<template>
  <div>
    <v-row>
      <v-col cols="4" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Fecha de inicio
        </span>
        <br />
        <span class="text-data-details">
          {{
            clienteInfo.fechaCreacion ? getFormattDate(clienteInfo.fechaCreacion) : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="4" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Hora
        </span>
        <br />
        <span class="text-data-details">
          {{
            clienteInfo.fechaCreacion ? getFormattHour(clienteInfo.fechaCreacion) : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="4" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Creado por
        </span>
        <br />
        <span class="text-data-details">
          {{
            clienteInfo.creadoPor ? clienteInfo.creadoPor : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="4" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Fecha de modificación
        </span>
        <br />
        <span class="text-data-details">
          {{
            clienteInfo.ultimaModificacion ? getFormattDate(clienteInfo.ultimaModificacion) : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="4" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Hora
        </span>
        <br />
        <span class="text-data-details">
          {{
            clienteInfo.ultimaModificacion ? getFormattHour(clienteInfo.ultimaModificacion) : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="4" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Modificado por
        </span>
        <br />
        <span class="text-data-details">
          {{
            clienteInfo.modificadoPor ? clienteInfo.modificadoPor : "Sin llenar"
          }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
 import moment from "moment";

export default {
  components: {},

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
    };
  },
  props: {
    clienteInfo: Object,
    operadores: Object,
    canEdit:{
      type: Boolean,
      default: true
    }
  },
  methods: {
    getAgentName() {
      return "Nombre";
    },
    getFormattDate(date){
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")
    },
    getFormattHour(date){
      return moment(date, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
    }
  },
};
</script>
