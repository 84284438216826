<template>
  <div>
    <CommonExpansionPanel>
      <template v-slot:title-panel>
        Historial de modificaciones 
      </template>
      <v-expansion-panel-content>
        <ViewLogCliente
          :clienteInfo="clienteInfo"
          :canEdit="canEdit"
        >
        </ViewLogCliente>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import ViewLogCliente from "./ViewLogCliente.vue";

export default {
  components: {
    CommonExpansionPanel,
    ViewLogCliente,
  },
  props: {
    clienteInfo: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      switch_data: 1,
    };
  },

  methods: {
    changeComponent(component) {
      this.switch_data = component;
    },
  },
};
</script>
